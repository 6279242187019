import { theme, rhythm } from '../utils/typography'
import { themeStyles, colors, metrics, presets } from '../utils/theme'
const styles = {
  referAFriendPage: {
    fontFamily: theme.donutFontFamily,
    minWidth: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${colors.lightGrey}`,
    padding: rhythm(1),
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    maxWidth: '700px',
    minWidth: '300px',
    backgroundColor: 'white',
    [presets.Tablet]: {
      minWidth: '400px',
    },
    flex: 1,
    ...themeStyles.shadow,
  },
  input: {
    padding: rhythm(1 / 2),
    marginBottom: rhythm(1 / 2),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
    marginTop: metrics.defaultMargin,
    marginBottom: metrics.defaultMargin,
    ...themeStyles.textPadding,
  },
  introText: {
    marginBottom: '20px',
    fontSize: '18px',
    fontWeight: 'bold',
    width: '45%',
    minWidth: '50%',
  },
  headerText: {
    fontWeight: 'bold',
    color: 'gray',
    marginTop: '0',
  },
  innerText: {
    fontWeight: 'bold',
    color: '#53d2e7',
  },
}

export default styles
