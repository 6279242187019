import React from 'react'

// App components
import { ReferAFriendForm } from '../../components/ReferAFriendForm'
import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'

// App utils
import { themeStyles } from '../../utils/theme'
import styles from '../../styles/refer-a-friend'
class Page extends React.Component {
  render() {
    var params = null
    var value = null
    if (typeof window !== `undefined`) {
      params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      })
    }

    if (params) {
      value = params.referralCode
    }

    if (value) {
      console.log('Collins staff member: ' + value)
    } else {
      value = 'not collins staff referred'
    }

    return (
      <Layout location={this.props.location} hideForm>
        <div id="refer-a-friend-div" css={{ ...themeStyles.contentWidth }}>
          <PageTitle title="Refer a Friend" bannerKey={'REFER_A_FRIEND'} />
          <div css={styles.container}>
            <h1 id="refer-a-friend-h2" css={styles.headerText}>
              Collins Referral Program
            </h1>
            <div css={styles.introText}>
              Refer your friends or family to Collins, and when they show up for
              their free consult, you both get entered into the contest!! Just
              fill out the info below and you’re set! Once you've completed the
              form, click on the{' '}
              <span css={styles.innerText}>Submit Referral</span> button at the
              bottom of the page. (*) are required fields.
            </div>
            <ReferAFriendForm referralCode={value} />
          </div>
        </div>
      </Layout>
    )
  }
}

export default Page
