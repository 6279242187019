import React from 'react'
import {
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from 'libphonenumber-js'

// App components
import InfusionsoftForm from './InfusionsoftForm'

// App utils
import { getRecaptchaId } from '../utils/formsConfig'
import { rhythm } from '../utils/typography'
import { themeStyles, colors, metrics, presets } from '../utils/theme'
import '../styles/form.css'

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${colors.lightGrey}`,
    padding: rhythm(1),
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    maxWidth: '700px',
    minWidth: '300px',
    backgroundColor: 'white',
    [presets.Tablet]: {
      minWidth: '400px',
    },
    flex: 1,
    ...themeStyles.shadow,
  },
  input: {
    padding: rhythm(1 / 2),
    marginBottom: rhythm(1 / 2),
    color: '#787878',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
    marginTop: metrics.defaultMargin,
    marginBottom: metrics.defaultMargin,
    ...themeStyles.textPadding,
  },
  buttonSubmit: {
    width: '100%',
    marginTop: rhythm(1),
    border: `2px solid white`,
    borderRadius: '3px',
    padding: '10px',
    fontSize: rhythm(1),
    fontFamily: 'sans-serif',
    fontWeight: '100',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    flex: -1,
    transition: 'all 0.3s ease',
    backgroundColor: colors.brightBlue,
    textDecoration: 'none',
    ...themeStyles.shadow,
    ':hover': {
      color: 'white',
      backgroundColor: colors.brightBlue,
    },
  },
  collinsText: {
    fontSize: '12px',
    color: '#53d2e7',
    marginBottom: '20px',
  },
  label: {
    fontWeight: 'bold',
  },
  errorMessage: {
    color: 'red',
    fontSize: '14px',
  },
}

export class ReferAFriendForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { phoneError: false, phoneLength: 0 }
    this.setPhoneError = this.setPhoneError.bind(this)
  }

  setPhoneError = (val) => {
    if (val) {
      var resp = isValidPhoneNumber(val, 'US')
      this.setState({ phoneError: resp, phoneLength: val.length })
    }
  }

  render() {
    const phoneError = this.state.phoneError
    const phoneLength = this.state.phoneLength

    return (
      <InfusionsoftForm
        id="refer-a-friend-form"
        css={styles.form}
        configKey="referAFriend"
      >
        <input
          type="hidden"
          id="inf_field_ReferralCode"
          name="inf_field_ReferralCode"
          value={this.props.referralCode}
        />
        <input
          type="hidden"
          id="inf_field_Email"
          name="inf_field_Email"
          value={'info@rochesterorthodontics.com'}
        />
        <label
          id="friend-name-label"
          htmlFor="inf_custom_ReferredBy"
          className="requiredCondensed"
          css={styles.label}
        >
          Your name:
        </label>
        <input
          css={styles.input}
          id="inf_custom_ReferredBy"
          name="inf_custom_ReferredBy"
          placeholder="Your first and last name"
          type="text"
          required
        />
        <label
          id="patient-first-name-label"
          htmlFor="inf_field_FirstName"
          className="requiredCondensed"
          css={styles.label}
        >
          First name of the friend you are referring:
        </label>
        <input
          css={styles.input}
          id="inf_field_FirstName"
          name="inf_field_FirstName"
          placeholder="Friend's first name"
          type="text"
          required
        />
        <label
          id="patient-last-name-label"
          htmlFor="inf_field_LastName"
          className="requiredCondensed"
          css={styles.label}
        >
          Last name of the friend you are referring:
        </label>
        <input
          css={styles.input}
          id="inf_field_LastName"
          name="inf_field_LastName"
          placeholder="Friend's last name"
          type="text"
          required
        />
        <label
          id="patient-phone-number-label"
          htmlFor="inf_field_Phone1"
          className="requiredCondensed"
          css={styles.label}
        >
          Friend's phone number (or responsible party if under 18 y.o.):
        </label>
        <input
          css={styles.input}
          id="inf_field_Phone1"
          name="inf_field_Phone1"
          placeholder="XXX-XXX-XXXX"
          onChange={(e) => {
            this.setPhoneError(e.target.value)
          }}
          type="tel"
          required
        />
        {!phoneError && phoneLength > 0 ? (
          <p css={styles.errorMessage}>Please enter a valid phone number</p>
        ) : null}
        <label
          id="patient-relationship-label"
          htmlFor="inf_custom_ResponsibleParty"
          css={styles.label}
        >
          Relationship to friend:
        </label>
        <input
          css={styles.input}
          id="inf_custom_ResponsibleParty"
          name="inf_custom_ResponsibleParty"
          placeholder="i.e. parent, sibling, friend, co-worker, etc."
          type="text"
        />
        <label
          id="comments-label"
          htmlFor="inf_custom_Message"
          css={styles.label}
        >
          Comments/additional info:
        </label>
        <textarea
          css={styles.input}
          id="inf_custom_Message"
          name="inf_custom_Message"
          placeholder="Optional"
        ></textarea>

        <div>
          <button
            css={styles.buttonSubmit}
            id={getRecaptchaId('referAFriend')}
            type="submit"
            disabled={!phoneError}
          >
            Submit Referral
          </button>
        </div>
      </InfusionsoftForm>
    )
  }
}
